export const BUSINESS_KEYWORD_RESPONSE = "BUSINESS_KEYWORD_RESPONSE"

export type BusinessKeywordResponse = {
    type: typeof BUSINESS_KEYWORD_RESPONSE;
    data: BusinessKeywordModel[];
};

export type BusinessKeywordModel = {
    index: number;
    id: string;
    description: string
}

export type CarrierDetailModel = {
    CarrierCode: string;
    CarrierName: string;
    CarrierCob: string;
}

export const InitBusinessKeywordModel = (): BusinessKeywordModel => {
    return {
        index: 0,
        id: "",
        description: ""
    };
}
