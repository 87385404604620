export const CARRIER_RECOMMENDATION_RESPONSE = "CARRIER_RECOMMENDATION_RESPONSE"

export type CarrierRecommendationResponse = {
    type: typeof CARRIER_RECOMMENDATION_RESPONSE;
    data: CarrierRecommendationModel;
};

export type CarrierRecommendationModel = {
    carrier: string,  
    businessClass: string   
}

export const InitCarrierRecommendationModel = (): CarrierRecommendationModel => {
    return {
        carrier: "",
        businessClass: ""       
    };
}
