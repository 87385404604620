import React, { useEffect, useState } from 'react';
import { useApi } from '../api';
import { ClientConfigData, CLIENT_CONFIG_RESPONSE, defaultConfig } from '../api/types/config-response';

const ClientConfigContext = React.createContext<ClientConfigData>({
    adobeAnalyticsUri: "",    
    configLoaded: false,
    geicoAppUrl: "https://www.geico.com",   
    isAutoRedirectEnabled: false,
    userType: "",
    adClientId: "",
    adInstance: "",
    graphUrl: ""
});

const ClientConfigContextProvider: React.FC<{
    children?: React.ReactNode;
}> = ({ children }) => {

    const api = useApi();

    const [config, setConfig] = useState<ClientConfigData>(defaultConfig);

    useEffect(() => {     
        api.commercialAgentApi.getClientConfig()
            .then((response) => {               
                if (response.type === CLIENT_CONFIG_RESPONSE) {                   
                    setConfig({ ...response.data, configLoaded: true });
                }
                else {
                    setConfig({ ...config, configLoaded: true });
                }
            }, (error) => {
                setConfig({ ...config, configLoaded: true });
                console.log(error);
            });
    }, []);

    return (
        <ClientConfigContext.Provider value={config}>
            {children}
        </ClientConfigContext.Provider>
    );
}

const useClientConfigs = (): ClientConfigData => {

    const context = React.useContext(ClientConfigContext);

    if (!context) {
        throw new Error(
            "useClientConfigs must be used within a ClientConfigProvider"
        );
    }

    return context;
}

export { ClientConfigContextProvider,  useClientConfigs };