type props = {
  title: string;
  content: string;
  closeAlert: Function;
};

export const Alert: React.FunctionComponent<props> = ({ title, content, closeAlert }) => {
  return (
    <div className="alert mt-10">
      <div className="alert--high-importance">
        <span className="icon-alert"></span>
        <ul className="alert-list">
          <li className="alert-content close-btn">
            <button aria-label="Close alert" className="icon-close-20" onClick={() => closeAlert()}></button>
            <h3 className="font--bold">{title}</h3>
            <p>{content}</p>
          </li>
        </ul>
      </div>
    </div>
  );
};
